import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.author} />
      <div className="header">
        <h1>{post.frontmatter.title}</h1>
      </div>
      <div className="content" style={{padding: "1rem 1rem 2.5rem 1rem"}}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }}, frontmatter: {template: { eq: "about" }}) {
      html
      frontmatter {
        template  
        title
        date 
      }
    }
  }
`